import $ from 'jquery';
import {isScrolledIntoView} from '../../assets/main'

$(document).ready(function() {
    $(window).scroll(function() {
        $('.timeline .item').each(function() {
            var element = $(this)
            if(isScrolledIntoView(element)) {
                element.addClass('show');
            }
        });
    });
});