import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

let $slider = $( 'div[is=hero-slider] .slider' ),
    options = {
        slide: '.slide',
        fade: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        speed: 1500,
        arrows: false,
        touchThreshold: 20,
        mobileFirst: true,
        rows: 0,
    };

if( $slider.data( 'autoplay' ) ) {
    options.autoplay = $slider.data( 'autoplay' );
}

if( $slider.data( 'autospeed' ) ) {
    options.autoplaySpeed = $slider.data( 'autospeed' );
}

$slider.slick(options);

$('.slide-arrow-next').click(function() {
    $slider.slick('slickNext');
});


$('.slider__arrows .prev').click(function() {
	$slider.slick('slickPrev');
});

$('.slider__arrows .next').click(function() {
	$slider.slick('slickNext');
});

$('.slick-slider').on('afterChange', function(slick, currentSlide){
    $slider.parent().find('.slider__nav b').text(currentSlide.currentSlide + 1);    
});

var words = document.querySelectorAll('.slide__title .title1');

[].forEach.call(words, function(e) {
    e.innerHTML = e.innerHTML.replace(/(^|<\/?[^>]+>|\s+)([^\s<]+)/g, '$1<span class="word">$2</span>');
});

$('[is=hero-slider] .slick-current').each(function() {
	var slide = $(this);
    slide.removeClass('slick-current');
    setTimeout(() => {
        slide.addClass('slick-current');
    }, 100);    
});