import $ from 'jquery';

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';


let $carousel = $( 'div[is=carousel] .carousel' ),
	options = {
		slide: 'div[is=carousel] .carousel-slide',
		slidesToShow: 1,
		arrows: false,
		infinite: true,
		touchThreshold: 20,
	    mobileFirst: true,
	    rows: 0,
		centerMode: false,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 1399,
				settings: {
				  slidesToShow: 3,
				}
			},
			{
			  breakpoint: 767,
			  settings: {
				slidesToShow: 2,
			  }
			}
		]
	};

if( $carousel.data( 'cols' ) ) {

	let $cols = $carousel.data( 'cols' ).split('-');

	options.responsive = [
		{
			breakpoint: 992,
			settings: {
				slidesToShow: parseInt($cols[3]),
				slidesToScroll: 1,
			}
		}, 
		{
			breakpoint: 768,
			settings: {
				slidesToShow: parseInt($cols[2]),
				slidesToScroll: 1
			}
		}, 
		{
			breakpoint: 570,
			settings: {
				slidesToShow: parseInt($cols[1]),
				slidesToScroll: 1
			}
		}, 
		{
			breakpoint: 320,
			settings: {
				slidesToShow: parseInt($cols[0]),
				slidesToScroll: 1
			}
		}
	];
}

if( $carousel.data( 'autoplay' ) ) {
    options.autoplay = $carousel.data( 'autoplay' );
}

if( $carousel.data( 'autoplayspeed' ) ) {
    options.autoplaySpeed = $carousel.data( 'autoplayspeed' );
}

// $carousel.on('init', function (event, slick) {
//     slick.$slides.css('height', slick.$slideTrack.height() + 'px');
// });

$carousel.slick(options); 

$('div[is=carousel] .arrows .prev img').click(function() {
	$(this).parent().parent().parent().find('.carousel').slick('slickPrev');
});

$('div[is=carousel] .arrows .next img').click(function() {
	$(this).parent().parent().parent().find('.carousel').slick('slickNext');
});