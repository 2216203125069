import $ from 'jquery';

/*--------------------------------------------
Infinite scroll
--------------------------------------------*/
if( $( '.load-more' ).length ) {

	let loading = '<div class="loading"><img src="' + FlyntData.templateDirectoryUri + '/assets/images/loading.svg" ></div>',
		container = $( '#grid' ),
		found = parseInt( container.data( 'found' ) ),
		loadMore = $( '.load-more' );

	loadMore.on( 'click', (e) => {

		e.preventDefault();

		let href = loadMore.attr( 'href' ),
			hrefSplit = href.split("/"),
			hrefIndex = parseInt(hrefSplit.length - 2),
			page = parseInt(hrefSplit[hrefIndex]),
			hrefNext = href.replace(/\d+/g, (page + 1));

			loadMore.attr( 'href', hrefNext );

			$.ajax({
				'url': href,
				'type': 'post',
				'dataType': 'html',
				'beforeSend': function () {
					loadMore.parent().prepend( loading );
				}
			})

			.done( function (response) {
				let items = $(response).find( '#grid' )[0].children;
				let itemsArray = Array.from(items);
				if( items.length ) {
					container.append(itemsArray);

					if( found == parseInt( container.find( '> div' ).length ) ) {
						loadMore.remove();
					}
				} else {
					loadMore.remove();
				}
			})

			.fail( function (code, status) {
				if( status === 'error' ) {
					loadMore.remove();
				}
			})

			.always( function (xhr, status) {
				if( $( '.loading' ).length ) {
					$( '.loading' ).remove();
				}
			});

	} );
}


$(document).ready(function() {
	if($('.filters').length && $('.filters .filter.active').data('slug') != 'all'){
		$('.loader').addClass('show');
		var termID = $('.filters .filter.active').data('term-id');
		console.log('TermID1 :' + termID);
		$('#grid .card-activity').each(function() {	
			var id = $(this).data('term-id');		
			console.log('TermID2 :' + id);
			if(String(id).indexOf(termID) != -1){
				$(this).show();
			}else{
				$(this).hide();			
			}			
		}).promise().done( function(){
			setTimeout(() => {
				$('.loader').removeClass('show');
			}, 200);			
		});
	}

	$('.filters .filter').click(function() {
		$('.filters .filter').removeClass('active');
    	$(this).addClass('active');
		$('.loader').addClass('show');
		var termID = $(this).data('term-id');
		$('#grid .card-activity').each(function() {
			var id = $(this).data('term-id');
			if(String(id).indexOf(termID) != -1){
				$(this).show();
			}else{
				if(termID == 'all') {
					$(this).show();
				}else{
					$(this).hide();
				}				
			}			
		}).promise().done( function(){
			setTimeout(() => {
				$('.loader').removeClass('show');
			}, 200);			
		});
	});	
});