import $ from 'jquery';

$('.filters li').click(function() {
    let name = $(this).data('name');

    $('.filters li').removeClass('active');
    $(this).addClass('active');

    $('.last-posts-carousel .posts .category').removeClass('active');
    $('.last-posts-carousel .posts [data-name="' + name + '"]').addClass('active');
    $('.last-posts-carousel .posts [data-name="' + name + '"] .carousel').slick('refresh');
});