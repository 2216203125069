import $ from 'jquery';

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';


let $carousel = $( 'div[is=slider-image] .slider' ),
	options = {
		dots: true,
		slidesToShow: 1,
		infinite: true,
		touchThreshold: 20,
	    mobileFirst: true,
        adaptiveHeight: true,
        prevArrow: $carousel.parent().find('.prev'),
        nextArrow: $carousel.parent().find('.next'),
	};

$carousel.slick(options); 