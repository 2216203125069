import $ from 'jquery';
import {isScrolledIntoView} from '../../assets/main';

$(window).scroll( function() {
	$('.category .line').each(function() {
		var $line = $(this);
		if(isScrolledIntoView($line)){
			$line.addClass('show');
		}
	});
});