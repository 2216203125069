import './scripts/publicPath'
import 'console-polyfill'
import './main.scss'
import $ from 'jquery'
// import AOS from 'aos/dist/aos';
// import 'aos/dist/aos.css';
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

import installCE from 'document-register-element/pony'

window.jQuery = $

window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.preloadAfterLoad = true
require('lazysizes')

installCE(window, {
  type: 'force',
  noBuiltIn: true
})

function importAll (r) {
  r.keys().forEach(r)
}

importAll(require.context('../Components/', true, /\/script\.js$/))

window.addEventListener("scroll", function() {
  const distance = window.scrollY;
  if(typeof(document.querySelector(".iconFloatPage")) != 'undefined' && document.querySelector(".iconFloatPage") != null){
    document.querySelector(".iconFloatPage").style.transform = `translateY(${distance * 0.1}px)`;
  }
  if(typeof(document.querySelector(".trianglePage")) != 'undefined' && document.querySelector(".trianglePage") != null){
    document.querySelector(".trianglePage").style.transform = `translateY(${distance * 0.1}px)`;
  }
});

$(document).ready(function() {

	if($('html.single-team').length) {
		if($('.last-posts-carousel .posts [data-name="news"] [is="query-posts"]').data('results') == false){
			$('.team-menu li[data-anchor="news"]').remove();
			$('.last-posts-carousel .filters li[data-name="news"]').remove();
		}

		if($('.last-posts-carousel .posts [data-name="event"] [is="query-posts"]').data('results') == false){
			$('.team-menu li[data-anchor="event"]').remove();
			$('.last-posts-carousel .filters li[data-name="event"]').remove();
		}else{
			if($('.last-posts-carousel .posts [data-name="news"] [is="query-posts"]').data('results') == false) {
				$('.last-posts-carousel .filters li[data-name="event"]').addClass('active');
				$('.last-posts-carousel .posts .category').removeClass('active');
				$('.last-posts-carousel .posts [data-name="event"]').addClass('active');
			}
		}

		if($('.last-posts-carousel .posts [data-name="print"] [is="query-posts"]').data('results') == false){
			$('.team-menu li[data-anchor="print"]').remove();
			$('.last-posts-carousel .filters li[data-name="print"]').remove();
		}else{
			if($('.last-posts-carousel .posts [data-name="news"] [is="query-posts"]').data('results') == false && $('.last-posts-carousel .posts [data-name="event"] [is="query-posts"]').data('results') == false) {
				$('.last-posts-carousel .filters li[data-name="print"]').addClass('active');
				$('.last-posts-carousel .posts .category').removeClass('active');
				$('.last-posts-carousel .posts [data-name="print"]').addClass('active');
			}
		}		
		
		if($('.areas-activity').length == false) {
			$('.team-menu li[data-anchor="activity"]').remove();
		}
	}

	$('.team-menu li').click(function() {
		if($(this).data('anchor') == 'activity'){
			var target = $('.areas-activity');
			$('html, body').animate({
				scrollTop: target.offset().top - 105
			}, 1000);
		}else if($(this).data('anchor') == 'news'){
			var target = $('[is="last-posts-carousel"]');
			$('html, body').animate({
				scrollTop: target.offset().top - 105
			}, 1000);
			$('.last-posts-carousel .filters li[data-name="news"]').click();
		}else if($(this).data('anchor') == 'event'){
			var target = $('[is="last-posts-carousel"]');
			$('html, body').animate({
				scrollTop: target.offset().top - 105
			}, 1000);
			$('.last-posts-carousel .filters li[data-name="event"]').click();
		}else if($(this).data('anchor') == 'print'){
			var target = $('[is="last-posts-carousel"]');
			$('html, body').animate({
				scrollTop: target.offset().top - 105
			}, 1000);
			$('.last-posts-carousel .filters li[data-name="print"]').click();
		}
	});

	if($('.related [is="query-posts"]').data('results') == false) {
		$('.related').hide();
	}

	if($('.share').length) {
		$('.share a').click(function(e) {
			if(this.getAttribute('data-network') != 'mail'){
				e.preventDefault();
				
				const href = this.getAttribute('href');
				const options = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,';
				const network = this.getAttribute('data-network');
				const networks = {
					facebook: { width: 600, height: 300 },
					twitter: { width: 600, height: 254 },
					google: { width: 515, height: 490 },
					linkedin: { width: 600, height: 473 }
				}
				
				window.open(href, '', options + 'height=' + networks[network].height + ',width=' + networks[network].width);
			}
		});
	}
});

/*--------------------------------------------
Debounce resize
--------------------------------------------*/
(function ($, sr) {

	// debouncing function from John Hann
	// http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
	var debounce = function (func, threshold, execAsap) {
		var timeout;

		return function debounced() {
			var obj = this,
				args = arguments;

			function delayed() {
				if (!execAsap)
					func.apply(obj, args);
				timeout = null;
			}

			if (timeout)
				clearTimeout(timeout);
			else if (execAsap)
				func.apply(obj, args);

			timeout = setTimeout(delayed, threshold || 100);
		};
	};
	// smartresize
	jQuery.fn[sr] = function (fn) {
		return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
	};

})(jQuery, 'smartresize');


const bps =  {
	smartphone: {
		label: 'sm',
		enter: 576,
		exit: 767				
	},
	tablet: {
		label: 'md',
		enter: 768,
		exit: 991
	},
	desktop: {
		label: 'lg',
		enter: 992,
		exit: 1199			
	},
	desktopBig: {
		label: 'xl',
		enter: 1200,
		exit: 200000000
	}
};



function onResize() {

	/* breakpoints helper classes on body */
	$( 'body' ).removeClass(function (index, css) {
		return (css.match (/\bbp-\S+/g) || []).join(' ');
	});

	if( window.matchMedia( "(max-width: " + bps.smartphone.exit +"px)" ).matches ) {
		$( 'body' ).addClass( 'bp-' + bps.smartphone.label);
	}

	if( window.matchMedia( "(min-width: " + bps.tablet.enter +"px)" ).matches && window.matchMedia( "(max-width: " + bps.tablet.exit +"px)" ).matches ) {
		$( 'body' ).addClass( 'bp-' + bps.tablet.label);
	}

	if (window.matchMedia("(min-width: " + bps.desktop.enter + "px)").matches && window.matchMedia("(max-width: " + bps.desktop.exit +"px)").matches ) {
		$( 'body' ).addClass( 'bp-' + bps.desktop.label);
	}

	if (window.matchMedia("(min-width: " + bps.desktopBig.enter + "px)").matches) {
		$('body').addClass('bp-' + bps.desktopBig.label);
	}



	/* orientation helper classes on body */
	var mql = window.matchMedia("(orientation: portrait)");

	// If there are matches, we're in portrait
	if( mql.matches ) {
		// Portrait orientation
		if(!$('body').hasClass('portrait') ) {
			$('body').removeClass('landscape');
			$('body').addClass('portrait');
		}
	} else {
		// Landscape orientation
		if(!$('body').hasClass('landscape') ) {
			$('body').removeClass('portrait');
			$('body').addClass('landscape');
		}
	}
	
}


// applico debounce al resize
$(window).smartresize(function () {
	onResize();
});

// applico debounce dopo caricamento window
$(window).on('load', function () {
	onResize();
});

$(window).scroll( function() {
	if( $(window).scrollTop() >= 40 ) {
		$('html').addClass('scroll');
	} else {
		$('html').removeClass('scroll');
	}
} );

if( $(window).scrollTop() >= 40 ) {
	$('html').addClass('scroll');
} else {
	$('html').removeClass('scroll');
}

export function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}